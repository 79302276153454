.custom-box {
    background: white;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0px 2px 10px -4px grey;

    .box-title {
        font-size: 115%;
        font-weight: 600;
    }
}

button,
label {
    &.tas {
        border: 1px solid;
        padding: 0.75rem 1rem; // make it a var
        border-radius: 4px;
        background-color: var(--tas-primary-color, #2cb052);
        border-color: var(--tas-primary-color, #2cb052);
        color: white;
        font-weight: 600;
        box-shadow: 0px 2px 5px -4px grey;
        position: relative;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 0px 6px 0px grey;
        }

        &.danger {
            border-color: rgb(235, 87, 87);
            background-color: rgb(235, 87, 87);
        }

        &.white {
            border-color: rgb(225, 225, 225);
            background-color: white;
            color: initial;
            font-weight: 500;
        }

        &.fullwidth {
            width: 100%;
        }

        .circular-progress {
            position: absolute;
            inset: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: inherit !important;
            color: inherit;
            z-index: 1;
            padding: 0.25rem;
        }
    }
}

.MuiTextField-root {
    svg {
        color: #121212;
        background-color: inherit;
    }
}
