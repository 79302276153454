.qrcode-left {
  width: 300px;
  width: calc(300px + 3rem);
  max-width: 100%;
}

.qrcode-right {
  width: 450px;
  width: calc(450px - 3rem);
  max-width: 100%;
}

.qrcode-left > div,
.qrcode-right > div {
  background-color: white;
  padding: 1rem;
  box-shadow: 1px 2px 2px 2px rgb(220 220 220);
  border-radius: 8px;
  margin: 0 0.5rem 0.5rem;
}

/* TODO: check setup flow*/
.your-qr-code-page {
  background-color: rgba(0, 0, 0, 0.05);
  min-height: 100%;
}

.floating-save {
  position: absolute;
  right: 1rem;
}

.floating-save .save-button {
  min-width: inherit;
}

.floating-save .save-button .lg-screen{
  display: none;
}

.bottom-row > div {
  margin-bottom: 1rem;
}

.qr-content {
  position: relative;
}

.qr-content > div {
  margin-bottom: 1rem;
}

.qr-dedign-options {
  margin-top: 0.5rem;
}

.qr-dedign-options input {
  border: 1px solid lightgray !important;
  padding: 0.5rem;
  border-radius: 5px;
}

.qr-design-option {
  display: inline-block;
  border-radius: 4px;
  margin-right: 1rem;
  position: relative;
  padding: 0.5rem;
  cursor: pointer;
  min-height: 2rem;
  min-width: 2rem;
  vertical-align: top;
}

.qr-design-option.white {
  box-shadow: 0px 0px 0px 1px rgb(220 220 220) !important;
}

.qr-dedign-options.logo .qr-design-option {
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 20%);
}

.qr-design-option.selected {
  box-shadow: 0px 0px 1px 2px rgb(45 211 93) !important;
}

.qr-dedign-options .custom-color-picker {
  box-shadow: 0px 0px 0px 0px;
}

.qr-design-option.square::before {
  content: "";
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #121212;
  transform: translate(-50%, -50%);
}

.qr-design-option.dot::before {
  content: "";
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background-color: #121212;
  transform: translate(-50%, -50%);
}

/* OLD */

.bottom-row {
  text-align: center;
}

.bottom-row > div {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.menu-line,
.menu-line-header,
.header-row {
  width: 750px;
  max-width: 100%;
  padding: 0.5rem;
  margin: auto;
}

.menu-line,
.menu-line-header {
  display: flex !important;
}

.menu-line {
  background-color: white;
  box-shadow: 1px 2px 2px 2px rgb(220 220 220);
  border-radius: 8px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.menu-line > div {
  display: flex;
  align-items: center;
  max-width: calc(100% - 150px);
}

.menu-name {
  width: 125px;
}

.menu-url > a {
  width: 400px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.copy {
  margin-left: 0.5rem;
  cursor: pointer;
  position: relative;
}

.copied {
  position: absolute;
  background-color: white;
  top: -1.5rem;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 1rem;
  padding: 4px;
}

.back-button {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: auto;
  cursor: pointer;
}

.your-qr-code-page #print-area > div:not(:first-child) {
  display: none;
}

#can {
  max-width: 100%;
}

.no-menu {
  text-align: center;
  margin-top: 7rem;
}

.no-menu > div {
  margin-bottom: 2rem;
}
