.pp-design-content {
  width: 100%;
  max-width: 58rem;
  display: flex;
  margin: auto;
}

.pp-design .design-container {
  width: 100%;
  max-width: 22rem;
  border: 0px !important;
  margin: auto;
  text-align: center;
}

.pp-design .custom-options {
  width: 100%;
  max-width: 22rem;
  margin: auto;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.pp-design .custom-options .back-arrow {
  margin-bottom: 1rem;
}

.pp-design .option-section .option-label {
  padding: 0.5rem;
}

.pp-design .custom-options .option-section {
  border: 1px solid lightgrey;
  border-radius: 4px;
  margin-top: 0.5rem;
  background: white;
  padding: 0.5rem;
}

.pp-design .custom-options .option-section .section-title {
  font-weight: 500;
  text-align: center;
  padding-top: 1rem;
}

.pp-design .custom-options .options {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.prefined-designs [name="sushi"] .preview > div,
.prefined-designs [name="default"] .preview > div {
  box-shadow: 0px 0px 0px 1px rgb(234, 222, 214);
}

.pp-design-content .custom-color-picker {
  width: 6rem;
}

.preview-laptop {
  overflow: hidden;
}

.laptop-container {
  width: 100%;
  padding-top: 59%;
  z-index: 1;
  position: relative;
}

.laptop-img {
  background: url("https://public.takeaseat.io/images/facing-laptop-2.png")
    center center / cover no-repeat;
  position: absolute;
  inset: 0;
  z-index: 5;
  pointer-events: none;
}

.mobile-container {
  width: 288px;
  padding-top: 570px;
  z-index: 1;
  position: relative;
  margin: 0 auto;
}

.mobile-img {
  background: url("https://admin.takeaseat.io/static/media/phone-facing-2.png")
    center center / contain no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.inner-laptop {
  position: absolute;
  top: 0;
  width: auto;
  margin: 4.2% 11.6% 6.9% 11.7%;
  inset: 0;
  overflow: hidden;
}

.inner-laptop,
.inner-mobile iframe {
  border: 0px;
}

.inner-mobile {
  position: absolute;
  top: 0;
  width: auto;
  margin: 14% 7% 6% 6%;
  inset: 0;
  overflow: hidden;
}

.device-selector {
  display: flex;
  margin: 0 auto;
}

.device-selector > div {
  margin: 1rem 0.5rem;
  background-color: white;
  border-radius: var(--tas-rad);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.device-selector > div.selected {
  background-color: var(--tas-primary-color, #5d935c);
  color: white;
  font-weight: bold;
  border-color: var(--tas-primary-color, #5d935c);
}

.pp-design-content .design-container {
  margin-bottom: auto;
}

@media (max-width: 640px) {
  .pp-design-content {
    flex-direction: column;
  }

  .pp-design-content .design-container {
    max-width: 100%;
  }
}
