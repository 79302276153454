#public-page-edit {
  flex-direction: column;
}

#public-page-edit .fullscreen {
  position: fixed;
  inset: 0;
}

.save-bar {
  max-width: 58rem;
  width: 100%;
  margin: 1rem auto;
  padding: 0.5 1rem;
}

.field-container {
  margin-bottom: 2rem;
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 1px 2px 2px 2px rgb(220 220 220);
}

.inline-field {
  display: block;
}

.inline-field.title {
  font-weight: bold;
  color: var(--tas-primary-active-color, #138030);
  margin: 0;
  display: flex;
  align-items: center;
}

.inline-field.title > div {
  vertical-align: top;
}

.inline-field.title > div:first-child {
  margin-right: 2rem;
  max-width: 100%;
  font-weight: 500;
  color: #212121;
}

.inline-field > div,
.inline-field > input {
  display: inline-block;
}

#public-page-edit .thumbnail {
  cursor: pointer;
}

#public-page-edit input,
#public-page-edit textarea {
  background: white;
  border: 1px solid lightgray;
  width: 100%;
}

/* Put in generic CSS */
.thumbnail {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
  vertical-align: middle;
  border-radius: 4px;
  overflow: hidden;
}

.thumbnail div.img,
.thumbnail.upload {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  width: 9rem;
  height: 5rem;
  background-size: cover;
}

.thumbnail.upload {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  display: inline-flex;
  flex-direction: column;
  background: grey;
  color: white;
}

.thumbnail.selected::after {
  background: rgba(90, 90, 90, 0.8);
  content: "Selected";
  font-weight: bold;
  text-align: center;
  inset: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.field[name="days"] .inline-field,
.field[name="menus"] .inline-field {
  display: flex;
}

.field[name="days"] .inline-field > .content,
.field[name="menus"] .inline-field > .content {
  width: 100%;
}

.field .clear-icon-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.field[name="days"] .inline-field > .content > div {
  width: 50% !important;
  display: inline-block;
  padding: 0.25rem;
}

.field[name="days"] {
  margin-top: 1rem;
}

.field[name="menus"] .inline-field > .content > div {
  width: 100%;
  display: inline-block;
  padding: 0.25rem;
}

/* Customization */
#public-page-edit .thumbnail {
  margin-left: 0;
}

.locked-feature {
  position: relative;
  background-color: white;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  border: 1px solid var(--tas-pro-color, orange);
  border-radius: var(--tas-rad);
  padding: 1rem;
}

.MuiPopover-paper {
  width: 100%;
  max-width: 16rem !important;
  margin: 0 1rem;
  padding: 0;
  background: rgb(250, 250, 250) !important;
}

.more-options > .field {
  margin-top: 1rem;
}

.message .field {
  position: relative;
}

.message .field > div {
  width: 100%;
  margin-top: 0.5rem;
}

.message textarea {
  border: 0 !important;
}

#website-ln-select .MuiButtonBase-root {
  display: none;
}

@media (max-width: 640px) {
  #public-page-edit .field-container {
    padding-right: 0;
  }
}

@media (min-width: 950px) {
  #public-page-edit .app-content > div {
    max-width: 58rem;
  }
}
