.save-edit-button div {
  cursor: pointer;
  font-size: 1.2rem;
  margin: auto 0 auto auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  border-radius: var(--tas-rad);
  /* color: #b1b1b1; */
}

.save-edit-button svg {
  margin: auto;
}

.save-edit-button .save-icon {
  background-color: var(--tas-third-color, orange);
}
