.pro-feature {
  display: flex;
}

.pro-feature svg {
  font-size: 0.9rem;
}

.pro-feature > div {
  display: flex;
  align-items: center;
  font-size: 70%;
  border: 2px solid var(--tas-pro-color);
  margin: auto;
  line-height: initial;
  border-radius: var(--tas-rad);
  padding: 0 0.3rem;
  color: var(--tas-pro-color);
  font-weight: bold;
  background-color: white;
}

.beta-feature {
  position: absolute;
  top: 0;
  right: -0.5rem;
  transform: translate(100%, 0);
  font-size: 50%;
  background: orange;
  padding: 0.2rem 0.5rem;
  border-radius: var(--tas-rad);
  font-weight: bold;
}

.modal-view.upgrade > .modal-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 40rem;
  min-height: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: white;
  flex-direction: column;
}

.modal-view.upgrade > .modal-content h1 {
  text-align: center;
  margin: auto;
  display: inline-block;
}

.modal-view.upgrade > .modal-content h1 > div {
  display: inline-block;
  padding: 1rem 0.5rem;
  vertical-align: middle;
}

.modal-view.upgrade > .modal-content h1 > div:nth-child(2) {
  max-width: 65%;
}

.modal-view.upgrade > .modal-content h1 > div:first-child {
  transform: scale(-1, 1);
}

.modal-view.upgrade > .modal-content .tas-button {
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.modal-view.invoices {
  padding: 1rem;
}

.modal-view.invoices > .content {
  background-color: white;
  margin-top: 6rem;
  padding: 2rem;
}

.modal-view.invoices .invoice {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 640px) {
  .modal-view.upgrade > .modal-content h1 {
    font-size: 150%;
  }

  .beta-feature {
    bottom: 0;
    left: 50%;
    top: unset;
    right: unset;
    transform: translate(-50%, 110%);
  }
}
