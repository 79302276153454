.tas-switch {
  position: relative;
  padding-right: 2rem;
  filter: invert(1);
  display: flex !important;
}

.tas-switch > div:first-child {
  display: inline-block;
  cursor: pointer;
  min-width: 3rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    90deg,
    white calc(33%),
    var(--tas-primary-color, #2CB052) calc(33%)
  );
  background-size: 400% 100%;
  border-radius: 4px;
  filter: invert(1);
}

.tas-switch > div:nth-child(2) {
  display: inline-block;
  cursor: pointer;
  min-width: 3rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    90deg,
    rgb(235, 87, 87) calc(33%),
    white calc(33%)
  );
  background-size: 400% 100%;
  border-radius: 4px;
  filter: invert(1);
}

.tas-switch.checked > div:first-child {
  background-position: 100%;
  transition: background-position 250ms ease;
  color: white;
}

.tas-switch:not(.checked) > div:first-child {
  background-position: 0%;
  transition: background-position 250ms ease;
}

.tas-switch.checked > div:nth-child(2) {
  background-position: 50%;
  transition: background-position 250ms ease;
}

.tas-switch:not(.checked) > div:nth-child(2) {
  background-position: 0%;
  transition: background-position 250ms ease;
  color: white;
}

@media (max-width: 640px) {
  .tas-switch {
    margin-left: auto;
  }
}
