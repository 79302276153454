#welcome-popup {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    padding: 1rem;
    display: flex;

    #popup-container {
        padding: 1.5rem;
        background-color: white;
        width: 100%;
        max-width: 26rem;
        margin: auto;
        border-radius: 12px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);

        h2 {
            text-align: center;
            margin-top: 0;
        }

        .desc {
            margin-block: 2.5rem;
        }
    }

    .buttons-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5rem;
    }

    .tas-button {
        border: 0px;
    }

    a {
        text-decoration: underline;
        color: rgba(0, 0, 0, 0.3);
        &:hover {
            cursor: pointer;
        }
    }
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    > div {
        width: 30rem;
        max-width: 100%;
        flex-grow: 1;
    }
}
