#setup-page {
  width: 100%;
  // min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background-color: red;
  background-image: radial-gradient(
    circle,
    #2f4858,
    #2b4c5d,
    #275063,
    #205467,
    #17586c,
    #115c6f,
    #0a5f72,
    #006375,
    #006778,
    #006b7a,
    #00707d,
    #00747f
  );

  .setup-page-content {
    background-color: white;
    margin: 0 auto;
    padding: 1.5rem;
    max-width: 94%;
    min-width: 23rem;
    position: relative;
    box-shadow: 0px 0px 6px 0px rgb(210, 210, 210);
    border-radius: 1rem;

    .plans {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
    }

    .page-title {
      font-size: 150%;
      text-align: center;
      margin-bottom: 2rem;
      // font-family: Lora, Nunito;
    }

    .field {
      margin-block: 1rem;
    }

    button {
      &.tas {
        text-transform: none;
        background: var(--tas-primary-color, #2cb052);
        font-weight: bold;
        font-size: inherit;
        border-radius: var(--tas-rad);
        width: 100%;

        &.pro {
          background-color: var(--tas-pro-color, #eabb2b);
        }

        svg {
          color: white;
        }
      }
    }
  }

  .service-tier {
    background-color: transparent;
    border: 2px solid;
    min-width: 20rem;
  }

  #logout-button-container {
    width: 100%;
    text-align: right;
    padding: 1rem;

    button {
      background: transparent;
      border: 0;
    }
  }

  .copyright {
    font-size: 80%;
    width: 100%;
    padding: 1rem;
    padding-top: 5rem;
    text-align: center;
    color: white;
  }
}

/*************************************************************/
.design-app .halves {
  display: flex;
  justify-content: space-evenly;
  max-width: 70rem;
  margin: auto;
  padding-top: 1rem;
}

.design-app .halves > div {
  max-width: 100%;
}

.prefined-designs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.prefined-designs > div {
  width: 8rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.prefined-designs [name="custom"] .design > div:first-child {
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgb(220, 220, 220);
}

.prefined-designs [name="ai"] .design > div:first-child {
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgb(220, 220, 220);
}

.prefined-designs .design {
  position: relative;
}

.prefined-designs .design.selected::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "✔";
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 2px 0px rgb(220, 220, 220);
}

.prefined-designs [name="light"] .preview > div {
  box-shadow: 0px 0px 0px 1px rgb(234, 222, 214);
}

.prefined-designs > div:hover .preview > div {
  box-shadow: 0px 0px 4px 2px rgb(210, 210, 210);
}

.templates-container {
  margin-bottom: 1.5rem;
}

.templates {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.templates > div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  border-radius: 6px;
  color: #00b0fb;
  font-weight: bold;
  padding: 0 0.5rem;
}

.templates > div.selected {
  color: #007cc2;
  box-shadow: 0px 0px 0px 2px #007cc2;
}

.left-column {
  padding: 4px;
}

.custom-color-picker {
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  flex: 1 1;
  border-radius: 6px;
  display: flex;
  padding: 0.9rem;
  justify-content: center;
  align-items: center;
}

.custom-options select {
  width: calc(50% - 0.5rem);
  border-radius: 6px;
  background-color: white;
  border: 0px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  height: 2.5rem;
  text-align: center;
  font-family: inherit;
  font-size: inherit;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/*********************************************************************************/

/* EOF Your style page */

/* START Your QR code page */
.your-qr-code-page .content {
  padding: 1rem;
}

.setup-page-content .your-qr-code-page > div {
  width: 100%;
  max-width: 35rem;
  border-radius: 8px;
}

.your-qr-code-page h1 {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  color: white;
}

.your-qr-code-page h1 > div:first-child {
  transform: scale(-1, 1);
  margin: 0 1rem 0 0;
}

.your-qr-code-page h1 > div:last-child {
  margin: 0 0 0 1rem;
}

.your-qr-code-page .row {
  width: 100%;
  max-width: 52rem;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.your-qr-code-page .qr-code-flyer {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  margin: auto auto 3rem auto;
  padding: 2rem;
}

.your-qr-code-page .logo-switch {
  margin: auto;
  width: max-content;
  width: fit-content;
  width: -moz-fit-content;
}

.your-qr-code-page .print-area {
  box-shadow: 0px 0px 5px 1px grey;
  width: fit-content;
  margin-bottom: 1rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.your-qr-code-page #print-area > div:not(:first-child) {
  display: none;
}

#print-area {
  display: flex;
  flex-direction: column;
}

.your-qr-code-page .option-buttons {
  display: flex;
  justify-content: space-evenly;
}

.your-qr-code-page .option-buttons > div,
.your-qr-code-page .option-buttons > a {
  padding: 0.5rem 1rem;
}

.your-qr-code-page .option-buttons > a:hover {
  text-decoration: none;
}

/* EOF Your QR code page */
