.custom-color-picker {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
}

.custom-color-picker .viewer {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
