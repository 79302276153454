.menu-edit {
  display: flex;
  justify-content: space-evenly;
}

.menu-edit > div {
  max-width: 100%;
}

.translation-menu {
  width: 100%;
  max-width: 50rem;
  margin: auto;
  background: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 1rem 1rem 4rem 1rem;
}

.translation-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.translation-details > div {
  display: inline-block;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.translation-options {
  text-align: center;
}

.translation-option {
  display: inline-block;
  vertical-align: top;
  flex-direction: column;
  padding: 0 1rem;
}

.translation-option .tas-button {
  margin: 0.5rem auto;
}

.translation-option .desc {
  font-size: 80%;
  color: grey;
  margin: auto;
  text-align: center;
}

.header-images {
  margin-right: auto;
  margin-left: 0;
  display: flex;
  align-items: center;
}

.header-images .img {
  width: 3rem;
  height: 2rem;
}

.section-content > .secondary-fieds {
  padding: 0 0.5rem;
  background: rgb(225, 225, 225);
  border-left: 1px solid;
  border-right: 1px solid;
}

.add-dish {
  background: lightgray;
  border-radius: 4px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  border: 1px solid rgba(33, 33, 33, 0.2);
  margin-bottom: 1rem;
}

.add-ele {
  background: grey;
  color: white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.add-ele:hover {
  background: #121212;
  color: white;
}

.positioning-arrows {
  margin-left: auto;
}

.item-menu {
  color: white;
  background-color: grey;
  border-radius: 50%;
  display: flex;
}

.item-menu svg {
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  padding: 0.25rem;
}

.create-menu {
  display: flex;
  flex-direction: column;
}

.create-menu > div {
  margin: 5rem auto;
  padding: 2rem;
  border: 1px solid lightgrey;
  border-radius: 0.5rem;
  background-color: white;
  width: calc(100% - 3rem);
  max-width: 35rem;
}

.item-edit-content .MuiTextField-root input {
  border: 0 !important;
}

.price-field,
.label-field {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem !important;
}

.price-field .field-description,
.label-field .field-description {
  font-weight: 600;
  font-size: 90%;
  margin-bottom: 0.5rem;
}

.field-description .edit-hide {
  visibility: hidden;
  cursor: pointer;
  margin-left: 0.5rem;
}

.field-description .edit-hide svg {
  width: 1.25rem;
  height: 1.25rem;
}

.field-description:hover .edit-hide {
  visibility: visible;
}

.price-field > span {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.price-field > span > div {
  display: inline-flex;
}

.price-field .currency {
  width: 14rem;
  max-width: 45%;
  position: relative;
}

.price-field .currency > div {
  min-width: 4rem;
  max-width: 7rem;
  margin-right: 0.5rem;
  width: 50%;
}

.price-field .desc {
  max-width: 15rem;
  margin-right: 0.5rem;
  width: 100%;
}

.price-format-select {
  display: inline-flex;
  padding: 0.625rem 1rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 1px lightgray;
  place-items: center;
}

.price-format-select.selected {
  background-color: rgb(220, 220, 220);
}

.price-format-select.selected > svg {
  width: 1rem;
  margin-left: 0.5rem;
  height: 1rem;
}

.format-selector {
  position: absolute;
  bottom: -1rem;
  right: 0px;
  font-size: 80%;
  text-align: end;
  cursor: pointer;
  color: dodgerblue;
}

.format-selector:hover {
  text-decoration: underline;
}

.secondary-fieds {
  display: flex;
  align-items: start;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.customization-field > span {
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  display: inline-block;
}

.customization-value {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.5rem;
}

.menu-edit .fullscreen {
  display: flex; /* TODO: put everywhere?*/
}

.section-selectors {
  display: flex;
  overflow: auto;
  margin-bottom: 2rem;
}

.section-selectors > div:not(:last-child):not(.selected):hover {
  background-color: grey;
  color: white;
}

.section-selectors > div:not(:last-child) {
  white-space: nowrap;
  padding: 0.5rem;
  border: 1px solid white;
  margin-right: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background: white;
  color: grey;
  font-weight: bold;
  position: relative;
}

.section-selectors > div.selected {
  background-color: grey;
  color: white;
}

.section-selectors > div.selected svg {
  fill: grey;
  background-color: white;
}

.create-ss {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 4px;
  background: lightgrey;
  cursor: pointer;
}

.create-ss:hover {
  background-color: grey;
  color: white;
}

.section-selectors-wrapper {
  padding: 1rem 0.5rem 0.25rem;
  background: rgb(242, 242, 242);
  position: sticky;
  top: 7.85rem;
  z-index: 999;
}

@media (min-width: 881px) {
  .menu-edit > div {
    max-width: calc(100% - 26rem);
  }
}

@media (min-width: 1024px) {
  .section-selectors-wrapper {
    top: 4.85rem;
  }
}

@media (max-width: 641px) {
  .button-wrapper > div {
    margin-left: auto;
    margin-right: auto;
  }
}
