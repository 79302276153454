.footer {
  width: 100%;
  height: 4.5rem;
  color: white;
  background-color: #383838;
  display: flex;
  font-weight: bold;
  z-index: 0;
  position: relative;
}

.footer > ul {
  display: none;
}

.footer a:hover {
  text-decoration: none;
}

.footer #lang-picker {
  margin: auto 7rem auto 1rem;
}

@media (max-width: 641px) {
  .footer .privacy {
    margin: auto auto auto 0rem !important;
  }

  .footer a {
    margin: auto 0.5rem !important;
  }

  .footer .privacy img {
    display: none;
  }
}
