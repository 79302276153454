.nav-list {
  max-height: 62px;
  /* min-height: 72px; */
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.nav-list.expanded {
  max-height: 100%;
  transition: all 0.5s ease-in-out;
}

.nav-list .label {
  position: relative;
}

.nav-list .label:after {
  content: "+";
  position: absolute;
  right: 0.5rem;
  font-size: 125%;
  cursor: pointer;
}

.nav-list.expanded .label:after {
  content: "-";
  font-size: 175%;
}

#nav a {
  text-decoration: none;
  color: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#nav .MuiPaper-root::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#nav .MuiPaper-root {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.nav-list.expanded li {
  background-color: rgba(9, 255, 80, 0.15);
  transition: background-color 0.3s ease-in-out;
}

.nav-list.expanded a:last-child li {
  border-radius: 0 0 12px 12px;
}

.nav-list.expanded .label {
  background-color: var(--tas-primary-color, #2cb052);
  transition: background-color 0.3s ease-in-out;
  color: white;
  border-radius: 12px 12px 0 0;
}

.nav-list.expanded svg {
  fill: white;
}

#nav .selected > li {
  color: #2cb052 ;
}

#nav .selected svg {
  fill: #2cb052 ;
}

#nav .selected span {
  font-weight: bold;
}

