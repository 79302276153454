.bubble-menu {
  display: flex;
}

.bubble-menu .menu-item {
  background: white;
  padding: 0.5rem;
  border-radius: 50%;
  border: 1px solid var(--tas-primary-color, #2cb052);
  color: var(--tas-primary-color, #2cb052);
  margin: 0.5rem;
  position: relative;
}

.bubble-menu .menu-item.selected {
  background: var(--tas-primary-color, #2cb052);
  color: white;
  box-shadow: 0px 0px 10px 3px grey;
}

.inbox {
  width: 100%;
  background: white;
  min-height: 4rem;
}

.message-highlight {
  display: inline-flex;
  border-bottom: 1px solid lightgrey;
  padding: 0.5rem;
  width: 100%;
  background: rgb(235, 235, 235);
  cursor: pointer;
}

.message-highlight:hover {
  box-shadow: 0px 2px 3px 0px grey;
}

.message-highlight > div {
  margin: auto 0;
}

.message-highlight .message-title {
  font-weight: bold;
  margin-right: 1ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 30%;
}

.message-highlight .message-body {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 70%;
}

.message-highlight .message-status,
.bubble-menu .message-status {
  margin-inline: 1rem;
  background-color: indianred;
  font-weight: bold;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.message-highlight svg {
  transform: none;
  margin: 0 0.5rem;
}

.Collapsible__contentInner {
  border: 0;
  margin-bottom: 1px; /* Otherwise, does not unfold the collapsible when set to lazy*/
}

.ticket-view {
  padding: 1rem;
  background: white;
  border: 1px solid lightgrey;
}

.ticket-post {
  background: white;
}

.ticket-post.restaurant {
  width: 90%;
  margin-right: auto;
  background: #e7eaf2;
  box-shadow: 2px 2px 3px 0px lightgrey;
}

.ticket-post.takeaseat {
  max-width: 90%;
  margin-left: auto;
  background: #e7f2ea;
  box-shadow: 2px 2px 3px 0px lightgrey;
}

#ticket-view h4 {
  margin-top: 1rem;
}

.ticket-title {
  font-size: 1.5rem;
}

.ticket-message {
  background-color: white;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  border: 2px solid lightgrey;
  border-radius: var(--tas-rad);
}

.ticket-posts {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.ticket-posts .ticket-post {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: var(--tas-rad);
}

.ticket-post-message {
  margin-bottom: 1rem;
  white-space: break-spaces;
}

.ticket-post-metadata {
  font-style: italic;
  font-size: small;
  margin: 0;
}

.whatsapp-support {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.faq.line > div.title {
  background: rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.faq-container {
  transition: max-height 1s ease-in-out;
  max-height: 0;  
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.faq-container.open {
  max-height: 315px;
}
