.modal-view {
  width: 55rem;
  margin: auto;
  max-width: 100%;
}

.modal-view.pricing {
  width: 75rem;
  padding: 1rem;
}

.modal-view .pricing-page {
  width: 75rem;
  margin-top: 4.5rem;
  max-width: 100%;
  overflow: auto;
  max-height: calc(100vh - 9rem);
  padding-top: 4rem;
  background-color: white;
}

.pricing-page .title {
  font-weight: 600;
  text-align: center;
}

.modal-view .clear-icon {
  background-color: white;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute !important;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
  filter: drop-shadow(1px 1px 1px);
  z-index: 1;
}

.tier-plan {
  position: relative;
}

.pricing-page {
  padding-top: 7rem;
  padding: 1rem;
}

.pricing-page > .desc {
  text-align: center;
  margin: 2rem 0;
}

.pricing-page .content {
  width: 95%;
  margin: 0 auto;
}

.pricing-page .content > div {
  margin-top: 2rem;
  display: inline-block;
  max-width: 25rem;
  margin: 2rem;
}

.pricing-page .trigger.open {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.pricing-page .trigger {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.service-tier {
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  padding: 1.5rem 2rem;
  text-align: left;
  line-height: initial;
  overflow: hidden;
  background-color: transparent;
  border: 2px solid;
  width: 21rem;
}

.service-tier .title {
  font-size: 120%;
  text-align: left;
}

.service-tier button.tas {
  text-transform: none;
  background: var(--tas-primary-color, #2cb052);
  font-weight: bold;
  font-size: inherit;
  border-radius: var(--tas-rad);
  width: 100%;
}

.service-tier button.pro {
  background-color: var(--tas-pro-color, #eabb2b);
  border: 0;
  box-shadow: 0 0 0 1px var(--tas-pro-color, #eabb2b);
}

#pricing .container {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 2rem;
  margin-block: 2rem;
}

#pricing .container > div {
  flex-grow: 1;
}

.pricing-page .highlights > div {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.pricing-page .highlights > div > svg {
  margin-right: 0.75rem;
  font-size: 130%;
}

.service-tier-price {
  margin: 1rem 0;
}

.service-tier-desc {
  margin-bottom: 1rem;
}

.service-tier-price .price {
  font-weight: bold;
  font-size: 275%;
}

.service-tier-feature {
  text-align: left;
  margin: 1rem;
}

.service-tier-feature ul {
  list-style: none;
  padding-left: 1rem;
}

.service-tier-feature ul > li {
  display: flex;
  margin-top: 1rem;
}

.service-tier-feature svg {
  color: rgb(200, 200, 200);
}

.service-tier-feature ul > li > div {
  margin: auto 0 auto 1rem;
}

.pricing-table {
  margin: 0 auto 3rem auto;
  background-color: white;
}

.pricing-table th,
.pricing-table td {
  border: 1px solid grey;
}

.pricing-table th {
  background: rgb(240, 240, 240);
  padding: 1rem;
}

.pricing-table th[scope="row"] {
  background: transparent;
  font-weight: normal;
  text-align: left;
}

.pricing-table thead th {
  padding: 1rem;
  min-width: 14rem;
  font-weight: initial;
  background: transparent;
}

.pricing-table thead th {
  text-align: center;
}

.pricing-table svg {
  color: #009b3e;
  width: 2rem;
  height: 2rem;
}

.pricing-table tr td {
  text-align: center;
}

.s-only {
  display: none;
}

.pricing-page > divlapsible__contentOuter {
  padding-inline: 1px;
}

.pricing-page .MuiToggleButtonGroup-grouped {
  border: 0px;
  border-radius: 0px !important;
}

.pricing-page .MuiToggleButtonGroup-grouped:hover {
  box-shadow: none;
}

.pricing-page .MuiToggleButton-root {
  font-family: inherit;
  text-transform: none;
}

.pricing-page .Mui-selected {
  /* background-color: transparent !important; */
  border-radius: 6px !important;
  font-weight: 600 !important;
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  text-decoration-thickness: 3px;
}

.pricing-page .Mui-selected:hover {
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  text-decoration-thickness: 3px;
}

@media (max-width: 759px) {
  .md-plus-only {
    display: none;
  }

  .s-only {
    display: initial;
  }

  .modal-view .clear-icon {
    transform: translate(0, -50%);
  }

  .modal-view .pricing-page {
    margin-top: 0;
    max-height: calc(100vh - 2rem);
    max-width: calc(100% - 2rem);
    margin: 1rem;
  }
}
