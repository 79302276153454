.login-page-content fieldset {
  border: 0;
  padding: 0;
}

.pwd-field {
  display: flex;
  position: relative;
}

.visibility-icon {
  cursor: pointer;
  margin: auto 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.requirements.hide {
  visibility: hidden;
}

.requirements {
  color: grey;
  font-weight: 400;
}

.requirements:not(.pwd) {
  color: red;
  margin-left: 0.5rem;
}

.requirements .requirement {
  display: inline-grid;
  margin-right: 1rem;
  position: relative;
  font-size: 80%;
  display: inline-block;
}

.requirements .requirement > div {
  display: flex;
}

.requirements .requirement:not(.is-met) .check-icon {
  display: none;
}

.check-icon svg {
  width: 1rem;
  height: 1rem;
}

.requirements .requirement.is-met {
  color: green;
}

.solution-selector {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.solution-selector.selected {
  box-shadow: 0px 0px 0px 2px var(--tas-primary-color, rgba(0,0,0,0.4));
  color: var(--tas-primary-color);
  font-weight: bold;
}