.identity-content {
    width: 100%;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    #upload-logo {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    #logo-edit {
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: contain;
        }
    }
}
